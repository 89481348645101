export const columns = [
  {
    label: 'name',
    field: 'label',
    width: '40%',
    filterOptions: {
      enabled: false,
      placeholder: ' ',
      filterValue: '',
    },
  },
  {
    label: 'Active',
    field: 'active',
    width: '10%',
    filterOptions: {
      enabled: false,
      placeholder: ' ',
      filterValue: '',
    },
  },
  {
    label: 'Waiting',
    field: 'waiting',
    width: '10%',
    filterOptions: {
      enabled: false,
      placeholder: ' ',
      filterValue: '',
    },
  },
  {
    label: 'Completed',
    field: 'completed',
    width: '10%',
    filterOptions: {
      enabled: false,
      placeholder: ' ',
      filterValue: '',
    },
  },
  {
    label: 'Failed',
    field: 'failed',
    width: '10%',
    filterOptions: {
      enabled: false,
      placeholder: ' ',
      filterValue: '',
    },
  },
  {
    label: 'Delayed',
    field: 'delayed',
    width: '10%',
    filterOptions: {
      enabled: false,
      placeholder: ' ',
      filterValue: '',
    },
  },
  {
    label: 'Paused',
    field: 'paused',
    width: '10%',
    filterOptions: {
      enabled: false,
      placeholder: ' ',
      filterValue: '',
    },
  },
]
