<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-card>
        <div slot="header">
          <strong>Система</strong>
        </div>
        <div class="links">
          <span @click="rebuildIndex">Перестроить индекс</span>
          <span @click="sortProducts">Пересортировать товары</span>
        </div>
      </b-card>
      <b-card>
        <div slot="header">
          <strong>Индексы</strong>
        </div>
        <div class="links">
          <grid-view-table
            id="queue-statistic"
            ref="queue-statistic"
            :columns="columns"
            :path="'queue-statistic'"
          />
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import { APIService } from '../../services/api'
import GridViewTable from '../../components/GridViewTable';
import {columns} from './grid-views/index';
import i18n from '@/plugins/i18n'

export default {
  name: 'SystemList',
  components: {
    GridViewTable,
  },
  data: () => {
    return {
      columns,
    }
  },
  methods: {
    async rebuildIndex() {
      try {
        this.$eventBus.$emit('alert', {
          type: 'success',
          text: i18n.t('Запрос на переиндексацию данных отправлен'),
        });
        APIService.get('index/rebuild');
      } catch (e) {
        this.$eventBus.$emit('alert', {
          type: 'danger',
          text: i18n.t('Ошибка при отправке запроса на переиндексацию данных'),
        });
      }
    },
    async sortProducts() {
      try {
        this.$eventBus.$emit('alert', {
          type: 'success',
          text: i18n.t('Запрос на сортировку товаров отправлен'),
        });
        APIService.get('index/resort');
      } catch (e) {
        this.$eventBus.$emit('alert', {
          type: 'danger',
          text: i18n.t('Ошибка при отправке запроса на сортировку товаров'),
        });
      }
    }
  }
}
</script>

<style lang="scss">
  .links {
    span {
      display: block;
      cursor: pointer;
      text-decoration: underline;
      margin: 3px auto;
    }
  }
</style>
